import React from 'react';
import logo from 'assets/images/logo.svg';
import logoDark from 'assets/images/logo.svg';
// material-ui
import { useTheme } from '@mui/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ===========================|| LOGO SVG ||=========================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
         <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="130" />
    );
};

export default Logo;
