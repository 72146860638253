import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDurabilitiesOnce = createAsyncThunk(
    "@Durability/once", async (params, { dispatch, getState }) => {
        const loaded = getDurabilityIsLoaded(getState())
        if (loaded) return new Promise()
        return await axios.get('/api/v1/durabilities', { params })
    }
);

export const createDurability = createAsyncThunk(
    "@Durability/create", async (params, { dispatch, getState }) => {
        return await axios.post('/api/v1/durabilities', params)
    }
);

export const initialState = {
    entities: {},
    selectOptions: [],
    status: 'new', // ['new', 'edited']
    loaded: false
};

const durabilitySlice = createSlice({
    name: '@Durability',
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDurabilitiesOnce.fulfilled, (state, { payload: { data: { data = [] } } }) => {
            state.loaded = true
            const newEntities = Object.fromEntries(data.map(({ attributes: { name }, id }) => {
                return [id, { label: name, value: id, id }]
            }))

            state.entities = { ...state.entities, ...newEntities };
            state.selectOptions = Object.values(state.entities)
        })

        builder.addCase(createDurability.fulfilled, (state) => {
            state.loaded = false
        })
    }
});

export const getDurabilitiesAsOptions = (state) => state.durabilities.selectOptions
export const getDurabilitiesRef = (state) => state.durabilities.entities
export const getDurabilityIsLoaded = (state) => state.durabilities.loaded

export const { setDefaults, setLoaded } = durabilitySlice.actions;
export default durabilitySlice.reducer;
