import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getProductCategoryDropdown = createAsyncThunk(
  `@ProductCategory/forDropdown`, async (params, thunkApi) => {
    return await axios.get(`/api/v1/product_categories/for_dropdown`, { params });
  }
);

const {reducer, List, Show, Update, Create, Delete} = BaseSlice(
  'ProductCategory',
  '/api/v1/product_categories',
  null,
  null,
  {
    setDefaults: (state) => {
      state.productCategoryDropdown = [];
    }
  },
  {
    [getProductCategoryDropdown.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.productCategoryDropdown = data;
    },
  }
);

export const getProductCategories = List;
export const getProductCategory = Show;
export const createProductCategory = Create;
export const deleteProductCategory = Delete;
export const updateProductCategory = Update;

export default reducer;
