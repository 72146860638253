import { Add } from "@mui/icons-material";
import { Box, Button, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, useFormikContext } from "formik";
import { isString } from "lodash";
import { useEffect, useState } from "react";
import CurrenciesAutocomplete from "ui-component/autocomplete/CurrenciesAutocomplete";
import SupplierAutocomplete from "ui-component/autocomplete/SupplierAutocomplete";
import UnitsAutocomplete from "ui-component/autocomplete/UnitsAutocomplete";
import FormikTextField from "ui-component/inputs/FormikTextField";
import UploadButton from "ui-component/UploadButton";
import fileDownload from 'js-file-download';
import axios from "axios";
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import contentDisposition from "content-disposition";

const useStyles = makeStyles((theme) => ({
    imageDelete: {
        cursor: 'pointer'
    }
}));

const SuppliersForm = () => {
    const formik = useFormikContext()
    const [defaultValue, setDefaultValue] = useState(0)
    const classes = useStyles();

    const handleAddDurability = (push) => {
        const newDurability = {
            supplier_id: '',
            price: '0',
            currency_id: '',
            unit_id: '',
            pieces_per_colli: '0',
            default: formik.values.suppliers.length === 0
        }
        push(newDurability)
    }

    useEffect(() => {
        const defaultIndex = formik.values.suppliers.findIndex(supplier => supplier.default);
        if (defaultIndex !== -1) {
            setDefaultValue(defaultIndex)
        }
    }, [formik.values])

    const defaultSupplierChanged = (event) => {
        formik.values.suppliers.forEach((supplier, index) => {
            formik.setFieldValue(`suppliers[${index}].default`, false)
        });

        formik.setFieldValue(`suppliers[${event.currentTarget.value}].default`, true)

    }

    const dettachFile = (index) => {
        formik.setFieldValue(`suppliers[${index}].attachment_id`, null)
    }

    const handleDownload = ({ attachment_id }) => {
        const fileDlUrl = `/api/v1/attachments/${attachment_id}/download`;

        axios.get(fileDlUrl, {
            responseType: 'blob'
        }).then(res => {
            const { parameters: filename } = contentDisposition.parse(res.headers["content-disposition"])
            fileDownload(res.data, filename.filename);
        });
    };

    return <Form>
        <Grid container spacing={2}>

            <FieldArray name='suppliers'>
                {({ push }) => <>
                    <Grid item xs={12}>
                        <FormHelperText error>{isString(formik.errors.suppliers) ? String(formik.errors.suppliers) : ''}</FormHelperText>
                        <TableContainer>
                            <RadioGroup
                                aria-labelledby="default-material-supplier-label"
                                value={defaultValue}
                                name="default-material-supplier"
                                onChange={defaultSupplierChanged}
                            >
                                <Table aria-labelledby="tableTitle" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Supplier</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Currency</TableCell>
                                            <TableCell>Weight</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Pieces per kolli</TableCell>
                                            <TableCell>Attachement</TableCell>
                                            <TableCell>Default</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {formik.values.suppliers.map((row, index) => {

                                            return (<TableRow key={index}>
                                                <TableCell>
                                                    <SupplierAutocomplete name={`suppliers[${index}].supplier_id`} size='small' />
                                                </TableCell>
                                                <TableCell>
                                                    <FormikTextField name={`suppliers[${index}].price`} size='small' />
                                                </TableCell>
                                                <TableCell>
                                                    <CurrenciesAutocomplete name={`suppliers[${index}].currency_id`} size='small' />
                                                </TableCell>
                                                <TableCell>
                                                    <FormikTextField name={`suppliers[${index}].weight`} size='small' />
                                                </TableCell>
                                                <TableCell>
                                                    <UnitsAutocomplete name={`suppliers[${index}].unit_id`} size='small' />
                                                </TableCell>
                                                <TableCell>
                                                    <FormikTextField name={`suppliers[${index}].pieces_per_colli`} size='small' />
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        row.attachment_id ?
                                                            <Box display="flex">
                                                                <a href="#download" onClick={() => handleDownload(row)}>Download</a>

                                                                <Close color="primary" className={classes.imageDelete} onClick={() => dettachFile(index)} />
                                                            </Box> :
                                                            <UploadButton name={`suppliers[${index}].attachment_id`} />
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel value={index}
                                                        control={<Radio />} label="" />
                                                </TableCell>
                                            </TableRow>)
                                        }
                                        )}
                                    </TableBody>
                                </Table>
                            </RadioGroup>

                        </TableContainer>
                        <Button type='button' size="small" color="primary" startIcon={<Add />} onClick={() => handleAddDurability(push)}>
                            Add Supplier
                        </Button>

                    </Grid>
                </>}
            </FieldArray>
        </Grid>
        <Grid container >
            <Grid item xs={12}>
                <Button variant="contained" type="submit">Save</Button>
            </Grid>
        </Grid>
    </Form>
}

export default SuppliersForm;
