import * as Yup from 'yup';

Yup.addMethod(Yup.array, "unique", function (
    message,
    mapper = (val) => val
) {
    return this.test(
        "unique",
        message,
        (list = []) => list.length === new Set(list.map(mapper)).size
    );
});