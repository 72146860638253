import { persistStore } from 'redux-persist';
import reducer from './reducer';
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import loaderMiddleware from './loaderMiddleware';
// ===========================|| REDUX - MAIN STORE ||=========================== //
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware, loaderMiddleware))

const store = createStore(reducer, composedEnhancer);
const persister = persistStore(store);

export { store, persister };
