const { Box } = require("@mui/material");

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            <Box
                sx={{
                    p: 0
                }}
            >
                {children}
            </Box>
        </div>
    );
}

export default TabPanel;