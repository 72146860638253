import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import {
    getSuppliersAsOptions,
    getSuppliersRef,
    getSupplierIsLoaded,
    getSuppliersOnce,
    setLoaded,
    createSupplier
} from 'features/suppliers/supplierSlice';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import errorProps from 'ui-component/inputs/error_props';
import useDialog from 'ui-component/dialogs/useDialog';
import { registerDialog } from 'ui-component/dialogs/DialogManger';

registerDialog('ConfirmNameDialog', () => import('ui-component/dialogs/ConfirmDialog'))

const SupplierAutocomplete = ({ name, ...props }) => {
    const dispatch = useDispatch();
    const [openDialog, closeDialog] = useDialog()
    const formik = useFormikContext()
    const loaded = useSelector(getSupplierIsLoaded)
    const options = useSelector(getSuppliersAsOptions)
    const optionsRef = useSelector(getSuppliersRef)

    useEffect(() => {
        if (!loaded) {
            dispatch(getSuppliersOnce({ 'page[size]': 'all' }))
            dispatch(setLoaded());
        }
    }, [loaded, dispatch])

    const value = getIn(formik.values, name) || ''
    const errors = errorProps(name, formik)

    const handleChange = (event, newValue, reason) => {
        if (newValue && newValue.create && reason === 'selectOption') {
            openDialog('ConfirmNameDialog', {
                open: true,
                title: 'Create',
                message: `Create Supplier "${newValue.value}"`,
                yesMessage: 'Create',
                noMessage: 'Cancel',
                handleCancel: closeDialog,
                handleOk: () => {
                    dispatch(createSupplier({ name: newValue.value }))
                    closeDialog()
                }
            });
            return
        }
        if (newValue && reason === 'selectOption') formik.setFieldValue(name, newValue.value);
        if (reason === 'clear') formik.setFieldValue(name, '');
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        stringify: (option) => option.label,
    });

    const filterOptionsWithAddNew = (options, state) => {
        const filtered = filterOptions(options, state)
        if (state.inputValue !== '') {
            filtered.push({ label: `Add "${state.inputValue}"`, value: state.inputValue, create: true })
        }
        return filtered
    }

    return (<Autocomplete
        disablePortal
        size="small"
        id='add-nutrition'
        options={options}
        filterOptions={filterOptionsWithAddNew}
        value={value}
        onChange={handleChange}
        getOptionLabel={(option) => getIn(optionsRef, `${option}.label`) || ''}
        renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}
        isOptionEqualToValue={(option, val) => {
            return option.value === val
        }}
        renderInput={(params) => {
            return <TextField size="small" {...params} {...errors} />
        }}
    />)
}

export default SupplierAutocomplete;