import { BaseSlice } from 'features/baseSlice';

const {reducer, List, Show, Update, Create, Delete} = BaseSlice('Stocks', '/api/v1/stocks')

export const getStocks = List
export const getStock = Show
export const createStock = Create
export const deleteStock = Delete
export const updateStock = Update

export default reducer;
