import { BaseSlice } from 'features/baseSlice';
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import axios from 'axios';

const initialState = {
  rows: [],
  metaData: {},
  isLoading: false,
  error: false,
  selected: {},
  payrollMessages: [],
};

const bslice = BaseSlice(
  'PayrollUpdateJobs',
  '/api/v1/payroll_update_jobs',
  initialState,
  null,
  {
    setDefaults: (state) => {}
  },
  {}
);
const { reducer, List, Show, slice: PayrollUpdateJobsSlice } = bslice;

// export const createPayrollDataReq = (params) => axios.post('/api/v1/payroll_update_jobs', params);

export const listPayrollUpdateJobs = List;
export const getPayrollUpdateJob = Show;
// export const createPayrollData = Create;
// export const deletePayrollData = Delete;
export const { setDefaults } = PayrollUpdateJobsSlice.actions;

export default reducer;
