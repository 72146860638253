import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Tab, Tabs } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import * as Yup from 'yup';
import usePageRefresh from 'views/usePageRefresh';
import ImageSlider from 'ui-component/ImageSlider';
import TabPanel from 'ui-component/TabPanel';
import { getRawMaterial, setDefaults, updateRawMaterial } from 'features/raw_materials/rawMaterialSlice';
import SuppliersForm from './forms/SuppliersForm';
import { isEmpty } from 'lodash';
import { Formik } from 'formik';
import RawMaterialInfo from './RawMaterialInfo';

// style constant
const useStyles = makeStyles((theme) => ({
    accountTab: {
        marginBottom: '24px',
        minHeight: 'auto',
        '& button': {
            minWidth: '100px'
        },
        '& a': {
            minHeight: 'auto',
            minWidth: '10px',
            padding: '12px 8px',
            marginRight: '18px',
            color: theme.palette.grey[600]
        },
        '& a.Mui-selected': {
            color: theme.palette.primary.main
        }
    },
    productStatus: {
        marginBottom: '20px'
    }
}));



// ===========================|| PRODUCT DETAILS ||=========================== //
const RawMaterialDetails = () => {
    const classes = useStyles();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { raw_material, status } = useSelector((state) => state.raw_materials);
    const { id } = useParams();

    const [value, setValue] = useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    usePageRefresh(() => {
        dispatch(getRawMaterial(id));
    }, status)

    useEffect(() => {
        dispatch(getRawMaterial(id));
        return () => dispatch(setDefaults())
    }, [dispatch, id]);

    useEffect(() => {
        if (status === 'unavailable') history('/products', { replace: true })
    }, [status, history])

    const validationSchema = Yup.object({
        suppliers: Yup.array().of(
            Yup.object().shape({
                supplier_id: Yup.string('Select supplier').required('Supplier is required'),
                price: Yup.number().typeError("Price must be an number").required('Price is required'),
                currency_id: Yup.string('Select currency').required('currency is required'),
                unit_id: Yup.string('Select unit').required('Unit is required'),
                pieces_per_colli: Yup.number().integer().typeError("Pieces must be an number").required('Pieces is required')
            })).unique("suppliers must be unique", (a) => { return a.supplier_id })
    });

    const initialValues = {
        suppliers: (raw_material && raw_material.material_suppliers) || [],
    }

    const getChanges = (toCompare, initials) => {
        const changes = []
        Object.entries(initials).forEach(([key, value]) => {
            if (!shallowEqual(toCompare[key], value)) {
                changes.push([key, toCompare[key]])
            }
        })
        return Object.fromEntries(changes)
    }

    const formik = {
        validationSchema: validationSchema,
        initialValues,
        enableReinitialize: true,
        onSubmit: (value, form) => {
            const changes = getChanges(value, initialValues)
            if (!isEmpty(changes)) {
                const payload = { raw_material: changes, id: raw_material.id };
                dispatch(updateRawMaterial(payload));
            }
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <MainCard>
                    {raw_material && (
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={6}>
                                <ImageSlider images={raw_material && raw_material.images} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RawMaterialInfo material={raw_material} />
                            </Grid>
                            <Grid item xs={12}>
                                <Tabs
                                    value={value}
                                    indicatorColor="primary"
                                    onChange={handleChange}
                                    className={classes.accountTab}
                                    aria-label="simple tabs example"
                                    variant="scrollable"
                                >
                                    <Tab component={Link} to="#description" label="Description" />
                                    <Tab component={Link} to="#suppliers" label="Suppliers" />
                                </Tabs>

                                <Formik {...formik} validateOnChange={false} validateOnBlur>
                                    <>
                                        <TabPanel value={value} index={1}>
                                        </TabPanel>


                                        <TabPanel value={value} index={1}>
                                            <SuppliersForm />
                                        </TabPanel>
                                    </>
                                </Formik>

                            </Grid>
                        </Grid>
                    )}
                </MainCard>
            </Grid>
        </Grid >
    );
};

export default RawMaterialDetails;
