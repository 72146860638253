import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const setTitle = createAsyncThunk(
  "@Page/setTitle", async(title, thunkApi) => {
    return { title };
  }
);

const pageSlice = createSlice({
  name: 'loader',
  initialState: {
    title: '',
  },
  reducers: {},
  extraReducers: {
    [setTitle.fulfilled]: (state, { payload: { title } }) => {
      state.title = title
    },
  }
});

export default pageSlice.reducer;
