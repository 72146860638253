import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers'
import axios from 'axios'

export const getSemiProducts = createAsyncThunk(
  "@SemiSemiProduct/index", async (params, thunkApi) => { return await axios.get('/api/v1/semi_products', { params }) }
);

export const getSemiProduct = createAsyncThunk(
  "@SemiProduct/show", async (id, thunkApi) => await axios.get(`/api/v1/semi_products/${id}`)
);

export const createSemiProduct = createAsyncThunk(
  "@SemiProduct/create", async (params, thunkApi) => await axios.post('/api/v1/semi_products', params)
);

export const deleteSemiProduct = createAsyncThunk(
  "@SemiProduct/delete", async (id, thunkApi) => await axios.delete(`/api/v1/semi_products/${id}`)
);

export const updateSemiProduct = createAsyncThunk(
  "@SemiProduct/update", async ({ id, ...params }, thunkApi) => await axios.put(`/api/v1/semi_products/${id}`, params)
);

export const getSemiProductColumnSelect = createAsyncThunk(
  "@SemiProduct/forColumnSelect", async (params, thunkApi) => { return await axios.get('/api/v1/semi_products/column_select', { params }) }
);

export const initialState = {
  rows: [],
  relationships: {},
  metaData: {},
  loading: false,
  error: false,
  reload: true,
  is_created: false,
  status: 'new', // ['new', 'edited']
  product: null,
  semiProductColumns: [],
};

const setRelationships = (state, payload) => {
  const relationships = {}

  payload.forEach(({ type, id, attributes }) => {
    if (!relationships[type]) relationships[type] = {}
    relationships[type][id] = { id, ...attributes }
  });

  return relationships
}

const productSlice = createSlice({
  name: '@SemiProduct',
  initialState,
  reducers: {
    setDefaults(state) {
      state.product = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSemiProducts.fulfilled, (state, { payload: { data: { meta, data, included = [] } } }) => {
      state.rows = data;
      state.relationships = setRelationships(state, included)
      state.metaData = meta;
    }).addCase(getSemiProduct.fulfilled, (state, { payload: { data: { meta, data, included = [] } } }) => {
      state.product = { id: data.id, ...data.attributes, relationships: setRelationships(state, included) }
    }).addCase(getSemiProductColumnSelect.fulfilled, (state, { payload }) => {
      state.semiProductColumns = payload.data;
    })

    pageMatcher(builder)
  }
});
export const { setDefaults } = productSlice.actions
export default productSlice.reducer;
