import React, { createContext, lazy, useState } from "react";
import Loadable from "ui-component/Loadable";
export const DialogContext = createContext({ open: false })

const dialogs = {}

export const registerDialog = (name, module) => {
    dialogs[name] = () => Loadable(lazy(module))
}

const Render = ({ dialog, ...props }) => {
    if (!dialogs[dialog]) return <></>;
    const View = dialogs[dialog]() ;
    return (<View {...props} />);
}

const DialogManager = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [dialog, setDialog] = useState('')
    const [componentProps, setComponentProps] = useState({})

    const closeDialog = () => {
        setOpen(false)
        setDialog(false)
        setComponentProps({})
    }

    const openDialog = (name, props) => {
        setDialog(name)
        setComponentProps(props)
        setOpen(true)
    }

    return (
        <DialogContext.Provider value={{ openDialog, closeDialog }}>
            {children}
            {open && <Render dialog={dialog} {...componentProps} />}
        </DialogContext.Provider>
    )
}

export default DialogManager;