import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

const productionUnitUrl = '/api/v1/production_unit';
const productionUnitIdfr = 'ProductionUnit';

export const getProductionUnitData = createAsyncThunk(
  `@${productionUnitIdfr}/getProductionUnitData`, async ({ id, ...params }, thunkApi) => {
    return await axios.get(`${productionUnitUrl}/${id}/production_data`, { params });
  }
);

export const createProductionUnit = createAsyncThunk(
  `@${productionUnitIdfr}/createWithError`, async (params, thunkApi) => {
    try {
      return await axios.post(productionUnitUrl, params);
    } catch (err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const deleteProductionUnit = createAsyncThunk(
  `@${productionUnitIdfr}/deleteWithError`, async (id, thunkApi) => {
    try {
      return await axios.delete(`${productionUnitUrl}/${id}`);
    } catch(err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const updateProductionUnit = createAsyncThunk(
  `@${productionUnitIdfr}/updateWithError`, async ({ id, ...params }, thunkApi) => {
    try {
      return await axios.put(`${productionUnitUrl}/${id}`, params);
    } catch(err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const getProductionUnitOrderData = createAsyncThunk(
  `@${productionUnitIdfr}/getProductionUnitOrderData`, async ({ id, ...params }, thunkApi) => {
    return await axios.get(`${productionUnitUrl}/${id}/production_data_by_order`, { params });
  }
);

export const setProductionUnitDone = createAsyncThunk(
  `@${productionUnitIdfr}/setProductionUnitDone`, async ({ id, ...params }, thunkApi) => {
    try {
      return await axios.put(`${productionUnitUrl}/${id}/set_order_product_done`, params);
    } catch(err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const getClientsForAutocomplete = createAsyncThunk(
  "@Clients/forAutocomplete", async (params, thunkApi) => {
    return await axios.get('/api/v1/clients_for_autocomplete', { params })
  }
);

export const getProductionUnitDropdown = createAsyncThunk(
  `@${productionUnitIdfr}/forDropdown`, async (params, thunkApi) => {
    return await axios.get(`${productionUnitUrl}/for_dropdown`, { params });
  }
);

const {reducer, List, Show} = BaseSlice(
  productionUnitIdfr,
  productionUnitUrl,
  null,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [getProductionUnitData.fulfilled]: (state, { payload: { data: { data, production_unit_name } } }) => {
      state.productionData = data;
      state.productionUnitName = production_unit_name;
    },
    [createProductionUnit.fulfilled]: (state, action) => {
      state.reload = true;
      state.is_created = true;
      state.status = 'edited';
    },
    [createProductionUnit.rejected]: (state, action) => {
      alert(action.payload.message);
    },
    [updateProductionUnit.fulfilled]: (state, action) => {
      state.reload = true;
      state.is_created = true;
      state.status = 'edited';
    },
    [updateProductionUnit.rejected]: (state, action) => {
      alert(action.payload.message);
    },
    [deleteProductionUnit.fulfilled]: (state, action) => {
      state.reload = true;
      state.status = 'edited';
    },
    [deleteProductionUnit.rejected]: (state, action) => {
      alert(action.payload.message);
    },
    [getProductionUnitOrderData.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.productionOrderData = data;
    },
    [setProductionUnitDone.fulfilled]: (state, { payload: { data } }) => {
      const { order_product_id, recipe_ingredient_id } = data;
      const { productionOrderData } = state;
      state.productionOrderData = productionOrderData.filter((pod) => (
        pod.order_product_id !== order_product_id || pod.recipe_ingredient_id !== recipe_ingredient_id
      ));
    },
    [getProductionUnitDropdown.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.productionUnitDropdown = data;
    },
  }
);

export const getProductionUnits = List;
export const getProductionUnit = Show;

export default reducer;
