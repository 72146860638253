import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getEntryRecordsDayView = createAsyncThunk(
  "@EntryRecord/getEntryRecordsDayView", async (params) => {
    return await axios.get('/api/v1/entry_records/day_view', { params });
  }
);

export const getTotalFacescanHours = createAsyncThunk(
  "@EntryRecord/getTotalFacescanHours", async (params) => {
    return await axios.get('/api/v1/entry_records/get_total_facescan_hours', { params });
  }
);

export const getTodaysAttendance = createAsyncThunk(
  "@EntryRecord/getTodaysAttendance", async (params) => {
    return await axios.get('/api/v1/entry_records/today', { params });
  }
);

export const getEntryRecordsEmployeeView = createAsyncThunk(
  "@EntryRecord/getEntryRecordsEmployeeView", async (params) => {
    return await axios.get('/api/v1/entry_records/employee_view', { params });
  }
);

const initialState = {
  rows: [],
  metaData: {},
  isLoading: false,
  error: false,
  selected: {},
  todaysAttendance: [],
  todaysAbsent: []
};

const bslice = BaseSlice(
  'EntryRecord',
  '/api/v1/entry_records',
  initialState,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [getEntryRecordsDayView.pending]: (state) => {
      state.isLoading = true;
      state.metaData = {};
    },
    [getEntryRecordsDayView.fulfilled]: (state, { payload: { data: { data, meta } } }) => {
      state.dayView = data;
      state.metaData = meta;
      state.isLoading = false;
    },
    [getTotalFacescanHours.pending]: (state) => {
      state.isLoading = true;
    },
    [getTotalFacescanHours.fulfilled]: (state, { payload: { data: { data, meta } } }) => {
      state.totalFacescanHours = data;
      state.metaData = meta;
      state.isLoading = false;
    },
    [getTodaysAttendance.pending]: (state) => {
      state.isLoading = true;
      state.todaysAttendance = [];
      state.todaysAbsent = [];
    },
    [getTodaysAttendance.fulfilled]: (state, { payload: { data: { data, meta } } }) => {
      const { present, absent } = data;
      state.todaysAttendance = present;
      state.todaysAbsent = absent
      state.isLoading = false;
    },
    [getEntryRecordsEmployeeView.pending]: (state) => {
      state.employeeView = { rows: [], columns: [] };
    },
    [getEntryRecordsEmployeeView.fulfilled]: (state, { payload: { data: { data, meta } } }) => {
      state.employeeView = data;
    },
  }
);
const { reducer, List, slice: EntryRecordSlice } = bslice;

export const getEntryRecords = List;
// export const getOrder = Show;
// export const createOrder = Create;
// export const deleteOrder = Delete;
// export const updateOrder = Update;
export const { setDefaults } = EntryRecordSlice.actions;

export default reducer;
