import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getClientsForAutocomplete = createAsyncThunk(
  "@Clients/forAutocomplete", async (params, thunkApi) => { return await axios.get('/api/v1/clients_for_autocomplete', { params }) }
);

const bslice = BaseSlice(
  'Clients',
  '/api/v1/clients',
  null,
  null,
  {
    setDefaults: (state) => {
      state.client = null;
    }
  },
  {
    [getClientsForAutocomplete.fulfilled]: (state, { payload }) => {
      state.autocompleteClients = payload.data;
    }
  }
);
const {reducer, List, Show, Update, Create, Delete, slice: ClientSlice} = bslice;

export const getClients = List
export const getClient = Show
export const createClient = Create
export const deleteClient = Delete
export const updateClient = Update
export const { setDefaults } = ClientSlice.actions

export default reducer;
