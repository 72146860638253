import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers'
import axios from 'axios'

export const getCurrencies = createAsyncThunk(
    "@Currency/index", async (params, thunkApi) => { return await axios.get('/api/v1/currencies', { params }) }
);

export const getCurrenciesOnce = createAsyncThunk(
    "@Currency/once", async (params, { dispatch, getState }) => {
        const loaded = getCurrenciesIsLoaded(getState())
        if (loaded) return new Promise()
        return await axios.get('/api/v1/currencies', { params })
    }
);

export const initialState = {
    rows: [],
    entities: {},
    selectOptions: [],
    status: 'new', // ['new', 'edited']
    loaded: false
};

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true
        },
    },
    extraReducers: (builder) => {
        [getCurrencies.fulfilled, getCurrenciesOnce.fulfilled].forEach((action) => {
            builder.addCase(action, (state, { payload: { data: { data = [] } } }) => {

                const newEntities = Object.fromEntries(data.map(({ attributes: { code }, id, ...props }) => {
                    return [id, { label: code, value: id, id }]
                }))

                state.entities = { ...state.entities, ...newEntities };
                state.selectOptions = Object.values(state.entities)
            })
        })
        pageMatcher(builder)
    }
});

export const getCurrenciesIsLoaded = (state) => state.currencies.loaded
export const getCurrenciesAsOptions = (state) => state.currencies.selectOptions
export const getCurrenciesRef = (state) => state.currencies.entities
export const { setLoaded } = currencySlice.actions
export default currencySlice.reducer;