import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProductReport = createAsyncThunk(
  "@Reports/products_index", async (params) => { return await axios.get('/api/v1/report/products_index', { params }); }
);

export const getOrderReport = createAsyncThunk(
  "@Reports/orders_index", async (params) => { return await axios.get('/api/v1/report/orders_index', { params }); }
);

export const getSalesReport = createAsyncThunk(
  "@Reports/sales_index", async (params) => { return await axios.get('/api/v1/report/sales_index', { params }); }
);

export const initialState = {
  products_report: {
    products: [],
    totalAmount: 0,
    totalVat: 0,
    totalDiscount: 0,
    currency: '',
  },
  orders_report: {
    orders: [],
    totalSmount: 0,
    totalVat: 0,
    totalDiscount: 0,
    currency: '',
  },
  sales_report: {
    sales: [],
    totalSmount: 0,
    totalVat: 0,
    totalDiscount: 0,
    currency: '',
  },
  isLoading: false,
};

const reportSlice = createSlice({
  name: '@Reports',
  initialState,
  reducers: {
    setDefaults(state) {
      state.orders = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProductReport.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getProductReport.fulfilled, (state, { payload: { data: { report } } }) => {
      const { products, total_amount, total_vat, total_discount, currency } = report;
      state.isLoading = false;
      state.products_report = {
        products,
        totalAmount: total_amount,
        totalVat: total_vat,
        totalDiscount: total_discount,
        currency,
      };
    });

    builder.addCase(getOrderReport.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getOrderReport.fulfilled, (state, { payload: { data: { report } } }) => {
      const { orders, total_amount, total_vat, total_discount, currency } = report;
      state.isLoading = false;
      state.orders_report = {
        orders,
        totalAmount: total_amount,
        totalVat: total_vat,
        totalDiscount: total_discount,
        currency,
      };
    });

    builder.addCase(getSalesReport.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getSalesReport.fulfilled, (state, { payload: { data: { report } } }) => {
      const { sales, total_amount, total_vat, total_discount, currency } = report;
      state.isLoading = false;
      state.sales_report = {
        sales,
        totalAmount: total_amount,
        totalVat: total_vat,
        totalDiscount: total_discount,
        currency,
      };
    });
  }
});

export default reportSlice.reducer;
