import { isFunction } from 'lodash';
import { TextField } from "@mui/material"
import { useFormikContext, getIn } from "formik"
import errorProps from "./error_props";



const FormikTextField = ({ name, children, handleChange, ...props }) => {
    const formik = useFormikContext()
    const value = getIn(formik.values, name) || ''
    const errors = errorProps(name, formik)

    props = {
        fullWidth: true,
        id: `${name}-field`,
        name: name,
        value: value,
        ...errors,
        ...props // keep it here to override props
    }

    const onChange = (e) => {
      if (isFunction(handleChange)) {
        handleChange(e.target.value);
      }
      formik.handleChange(e);
    };

    return <TextField onChange={onChange} onBlur={formik.handleBlur} {...props} >
        {children}
    </TextField>
}

export default FormikTextField;
