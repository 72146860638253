import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getIngredientNamesOnce = createAsyncThunk(
    "@IngredientName/once", async (params, { dispatch, getState }) => {
        const loaded = getIngredientNameIsLoaded(getState())
        if (loaded) return new Promise()
        return await axios.get('/api/v1/ingredient_names', { params })
    }
);

export const createIngredientName = createAsyncThunk(
    "@IngredientName/create", async (params, { dispatch, getState }) => {
        return await axios.post('/api/v1/ingredient_names', params)
    }
);

export const initialState = {
    entities: {},
    selectOptions: [],
    status: 'new', // ['new', 'edited']
    loaded: false
};

const ingredientNameSlice = createSlice({
    name: '@IngredientName',
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true
        },
        addOption(state, { payload: { id, name } }) {
            const newEntities = { [id]: { label: name, value: id, id } }
            state.entities = { ...state.entities, ...newEntities };
            state.selectOptions = Object.values(state.entities)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getIngredientNamesOnce.fulfilled, (state, { payload: { data: { data = [] } } }) => {
            state.loaded = true
            const newEntities = Object.fromEntries(data.map(({ attributes: { name }, id }) => {
                return [id, { label: name, value: id, id }]
            }))

            state.entities = { ...state.entities, ...newEntities };
            state.selectOptions = Object.values(state.entities)
        })

        builder.addCase(createIngredientName.fulfilled, (state) => {
            state.loaded = false
        })
    }
});

export const getIngredientNamesAsOptions = (state) => state.ingredient_names.selectOptions
export const getIngredientNamesRef = (state) => state.ingredient_names.entities
export const getIngredientNameIsLoaded = (state) => state.ingredient_names.loaded

export const { setDefaults, setLoaded, addOption } = ingredientNameSlice.actions;
export default ingredientNameSlice.reducer;
