import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCountriesOnce = createAsyncThunk(
  "@Country/once", async (params, { dispatch, getState }) => {
    const { countries } = getState()
    if (countries.loaded) return new Promise()
    return await axios.get('/api/v1/countries', { params })
  }
);

export const getCountries = createAsyncThunk(
  "@Country/index", async (params, thunkApi) => { return await axios.get('/api/v1/countries', { params }) }
);

export const initialState = {
  countries: [],
  loading: false,
  error: false,
  reload: true,
  rows: [],
  entities: {},
  selectOptions: [],
  status: 'new', // ['new', 'edited']
  loaded: false
};

const countrySlice = createSlice({
  name: '@Country',
  initialState,
  reducers: {
    setLoaded(state) {
      state.loaded = true
    },
    setDefaults(state) {
      state.countries = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, { payload: { data: { meta, data, included = [] } } }) => {
      state.countries = data;
    })

    builder.addCase(getCountriesOnce.fulfilled, (state, { payload: { data: { data = [] } } }) => {
      state.loaded = true
      const newEntities = Object.fromEntries(data.map(({ attributes: { name, alpha_2_code }, id }) => {
        return [id, { label: name, value: id, id, code: alpha_2_code }]
      }))

      state.entities = { ...state.entities, ...newEntities };
      state.selectOptions = Object.values(state.entities)
    })

  }
});

export const getCountriesAsOptions = (state) => state.countries.selectOptions
export const getCountrysRef = (state) => state.countries.entities
export const getCountryIsLoaded = (state) => state.countries.loaded

export const { setDefaults, setLoaded } = countrySlice.actions;
export default countrySlice.reducer;
