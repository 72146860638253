import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import StoreIcon from '@mui/icons-material/Store';
import Storefront from '@mui/icons-material/Storefront';
import PersonIcon from '@mui/icons-material/Person';
import Article from '@mui/icons-material/Article'
import Summarize from '@mui/icons-material/Summarize'
import Assessment from '@mui/icons-material/Assessment'
import ProductionIcon from '@mui/icons-material/ProductionQuantityLimits';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import InventoryIcon from '@mui/icons-material/Inventory';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import PeopleIcon from '@mui/icons-material/People';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';


// constant
const icons = {
  IconDashboard, IconDeviceAnalytics, StoreIcon, Storefront, PersonIcon,
  Article, ProductionIcon, Summarize, Assessment, BrunchDiningIcon,
  InventoryIcon, BakeryDiningIcon, PeopleIcon, SafetyDividerIcon,
  ManageAccountsIcon,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'products',
            title: <FormattedMessage id="Products" />,
            type: 'item',
            url: '/products',
            icon: icons.Storefront,
            breadcrumbs: false
        },
        {
            id: 'semi_products',
            title: <FormattedMessage id="Semi Products" />,
            type: 'item',
            url: '/semi_products',
            icon: icons.BrunchDiningIcon,
            breadcrumbs: false
        },
        {
            id: 'raw_materials',
            title: <FormattedMessage id="Raw materials" />,
            type: 'item',
            url: '/raw_materials',
            icon: icons.BakeryDiningIcon,
            breadcrumbs: false
        },
        {
            id: 'stocks',
            title: <FormattedMessage id="Stocks" />,
            type: 'item',
            url: '/stocks',
            icon: icons.InventoryIcon,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: <FormattedMessage id="Clients" />,
            type: 'item',
            url: '/clients',
            icon: icons.PersonIcon,
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: <FormattedMessage id="Orders" />,
            type: 'item',
            url: '/orders',
            icon: icons.Article,
            breadcrumbs: false
        },
        {
            id: 'purchase_lists',
            title: <FormattedMessage id="Purchases" />,
            type: 'item',
            url: '/purchase_lists',
            icon: icons.Summarize,
            breadcrumbs: false
        },
        {
            id: 'production',
            title: <FormattedMessage id="Production" />,
            type: 'item',
            url: '/production',
            icon: icons.ProductionIcon,
            breadcrumbs: false
        },
        {
            id: 'reports',
            title: <FormattedMessage id="Reports" />,
            type: 'item',
            url: '/reports',
            icon: icons.Assessment,
            breadcrumbs: false
        },
        {
            id: 'entry_records',
            title: <FormattedMessage id="Entry Records" />,
            type: 'item',
            url: '/entry_records',
            icon: icons.PeopleIcon,
            breadcrumbs: false,
            adminOnly: true,
            showForHr: true
        },
        {
            id: 'employees',
            title: <FormattedMessage id="Employees" />,
            type: 'item',
            url: '/employees',
            icon: icons.SafetyDividerIcon,
            breadcrumbs: false,
            adminOnly: true,
            showForHr: true
        },
        {
            id: 'users',
            title: <FormattedMessage id="Users" />,
            type: 'item',
            url: '/users',
            icon: icons.ManageAccountsIcon,
            breadcrumbs: false,
            adminOnly: true
        }
    ]
};

export default dashboard;
