import { useCallback, useEffect, useState } from 'react'

const usePageRefresh = (callback, status) => {
    const [pageStatus, setPageStatus] = useState('idle')

    const memoizedCallback = useCallback(() => {
        callback()
    }, [callback])

    useEffect(() => {
        if (status === 'edited') {
            setPageStatus('refresh')
        }
    }, [status, setPageStatus])


    useEffect(() => {
        if (pageStatus === 'refresh') {
            memoizedCallback()
            setPageStatus('idle')
        }
    }, [pageStatus, memoizedCallback])
}

export default usePageRefresh;