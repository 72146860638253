import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDashboard = createAsyncThunk(
  "@Dashboard/index", async (_) => { return await axios.get('/api/v1/dashboard'); }
);

export const initialState = {
  dashboard: {},
  isLoading: false,
};

const dashboardSlice = createSlice({
  name: '@Dashboard',
  initialState,
  reducers: {
    setDefaults(state) {
      state.dashboard = {};
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getDashboard.fulfilled, (state, { payload: { data: { dashboard } } }) => {
      state.isLoading = false;
      state.dashboard = dashboard;
    });
  }
});

export default dashboardSlice.reducer;
