import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import {LocalShipping} from '@mui/icons-material'
import CategoryIcon from '@mui/icons-material/Category';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import AccountTree from '@mui/icons-material/AccountTree';
import MailIcon from '@mui/icons-material/Mail';
import RuleIcon from '@mui/icons-material/Rule';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SummarizeIcon from '@mui/icons-material/Summarize';
// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
  IconDashboard, IconDeviceAnalytics, LocalShipping, StoreMallDirectoryIcon, CategoryIcon,
  MailIcon, RuleIcon, AccountTree, IntegrationInstructionsIcon, SummarizeIcon,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const buildChildren = () => {
  let children = [
      {
        id: 'suppliers',
        title: <FormattedMessage id="Suppliers" />,
        type: 'item',
        url: '/suppliers',
        icon: icons.LocalShipping,
        breadcrumbs: false
      },
      {
        id: 'stock-placements',
        title: <FormattedMessage id="Stock placements" />,
        type: 'item',
        url: '/stock_placements',
        icon: icons.StoreMallDirectoryIcon,
        breadcrumbs: false
      },
      {
        id: 'categories',
        title: <FormattedMessage id="Categories" />,
        type: 'item',
        url: '/categories',
        icon: icons.CategoryIcon,
        breadcrumbs: false
      },
      {
        id: 'product_categories',
        title: <FormattedMessage id="Product categories" />,
        type: 'item',
        url: '/product_categories',
        icon: icons.CategoryIcon,
        breadcrumbs: false
      },
      {
        id: 'mailers',
        title: <FormattedMessage id="Mailers" />,
        type: 'item',
        url: '/mailers',
        icon: icons.MailIcon,
        breadcrumbs: false
      },
      {
        id: 'production_units',
        title: <FormattedMessage id="Production units" />,
        type: 'item',
        url: '/production_units',
        icon: icons.AccountTree,
        breadcrumbs: false
      },
      {
        id: 'rules',
        title: <FormattedMessage id="Rules" />,
        type: 'item',
        url: '/rules',
        icon: icons.RuleIcon,
        breadcrumbs: false,
        adminOnly: true
      },
      {
        id: 'oauth_integrations',
        title: <FormattedMessage id="Oauth Integrations" />,
        type: 'item',
        url: '/oauth_integrations',
        icon: icons.IntegrationInstructionsIcon,
        breadcrumbs: false,
        devOnly: true
      },
      {
        id: 'report_templates',
        title: <FormattedMessage id="Report Templates" />,
        type: 'item',
        url: '/report_templates',
        icon: icons.SummarizeIcon,
        breadcrumbs: false
      },
  ];

  return children;
};

const settings = {
    id: 'settings',
    title: <FormattedMessage id="settings" />,
    type: 'group',
    children: buildChildren()
};

export default settings;
