import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, CardMedia, Grid, useMediaQuery } from '@mui/material';

// project import
import MainCard from 'ui-component/cards/MainCard';
import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';

// third-party
import Slider from 'react-slick';
import Carousel, { Modal, ModalGateway } from 'react-images';

// ===========================|| PRODUCT DETAILS - IMAGES ||=========================== //

const ImageSlider = ({ images, customStyle = {} }) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const [imagesForCarousel, setImagesForCarousel] = useState([]);
    const [assets, setAssets] = useState([]);

    useEffect(() => {
        if (images) {
            const imgs = Object.values(images).map((item) => item)
            setSelected(imgs[0] || {})
            setAssets(imgs)
            setImagesForCarousel(imgs.map((item) => ({ source: item.large })));
        }
    }, [images]);

    const matchDownLG = useMediaQuery(theme.breakpoints.up('lg'));

    const [selected, setSelected] = React.useState({});
    const [modal, setModal] = React.useState(false);

    const noLG = matchDownLG ? 4 : 3;
    const settings = {
        dots: false,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '0px',
        slidesToShow: assets.length > 3 ? noLG : assets.length
    };

    const cardStyling = () => {
      const defaultStyle = { borderRadius: `${customization.borderRadius}px`, overflow: 'hidden', cursor: 'zoom-in' };
      return Object.assign(defaultStyle, customStyle);
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={gridSpacing}>
                <Grid item xs={12}>
                    <MainCard content={false} sx={{ m: '0 auto' }}>
                        <CardMedia
                            onClick={() => setModal(!modal)}
                            component="img"
                            image={selected.carousel}
                            sx={cardStyling()}
                        />
                    </MainCard>
                </Grid>
                <Grid item xs={11} sm={7} md={9} lg={10} xl={8}>
                    <Slider {...settings}>
                        {assets.map((item, index) => (
                            <Box key={index} onClick={() => setSelected(item)} sx={{ p: 1 }}>
                                <Avatar
                                    outline={selected === item}
                                    size={matchDownLG ? 'lg' : 'md'}
                                    color="primary"
                                    src={item.thumbnail}
                                    variant="rounded"
                                    sx={{ m: '0 auto' }}
                                />
                            </Box>
                        ))}
                    </Slider>
                </Grid>
            </Grid>
            <ModalGateway>
                {modal ? (
                    <Modal onClose={() => setModal(!modal)}>
                        <Carousel views={imagesForCarousel} />
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    );
};

ImageSlider.propTypes = {
    images: PropTypes.object
};

export default ImageSlider;
