import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUserSetting = createAsyncThunk(
  `@UserSetting/show`, async () => await axios.get(`/api/v1/show_user_settings`)
);

export const saveUserSetting = createAsyncThunk(
  `@UserSetting/save`, async ({ ...params }) => await axios.put(`/api/v1/save_user_settings`, params)
);

export const initialState = {
  loaded: false,
  isSaved: false,
  settings: {},
};

const userSettingSlice = createSlice({
  name: '@UserSetting',
  initialState,
  reducers: {
    setLoaded(state) {
      state.loaded = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserSetting.pending, (state) => {
      state.loaded = false;
    })
    builder.addCase(getUserSetting.fulfilled, (state, { payload: { data } }) => {
      state.settings = data?.data?.attributes || {};
      state.loaded = true;
    })
    builder.addCase(saveUserSetting.pending, (state) => {
      state.isSaved = false;
    })
    builder.addCase(saveUserSetting.fulfilled, (state, { payload: { data: { data }} }) => {
      state.settings = data.attributes;
      state.isSaved = true
    })
  }
});

export default userSettingSlice.reducer;
