import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getAddressesForAutocomplete = createAsyncThunk(
  "@Addresses/forAutocomplete", async (_) => {
    return await axios.get('/api/v1/addresses_for_autocomplete');
  }
);

export const getAddressesByClientForAutocomplete = createAsyncThunk(
  "@Addresses/forAutocompleteByClient", async (params) => {
    return await axios.get('/api/v1/addresses_by_client_for_autocomplete', { params });
  }
);

export const initialState = {
  autocompleteAddresses: [],
  autocompleteAddressesByClient: [],
};

const addressesSlice = createSlice({
  name: '@Addresses',
  initialState,
  reducers: {
    setLoaded(state) {
      state.loaded = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAddressesForAutocomplete.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAddressesForAutocomplete.fulfilled, (state, { payload: { data: autocompleteAddresses } }) => {
      state.isLoading = false;
      state.autocompleteAddresses = autocompleteAddresses;
    });

    builder.addCase(getAddressesByClientForAutocomplete.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAddressesByClientForAutocomplete.fulfilled, (state, { payload: { data: autocompleteAddresses } }) => {
      state.isLoading = false;
      state.autocompleteAddressesByClient = autocompleteAddresses;
    });
  }
});

export const { setLoaded } = addressesSlice.actions;
export default addressesSlice.reducer;
