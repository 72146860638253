import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const deleteAmendOrderRule = createAsyncThunk(
  "@AmendOrder/delete", async (id, thunkApi) => await axios.delete(`/api/v1/amend_order_rules/${id}`)
);

const bslice = BaseSlice(
  'AmendOrder',
  '/api/v1/amend_order_rules',
  null,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [deleteAmendOrderRule.fulfilled]: (state, action) => {
      state.reload = true;
      state.status = 'edited'
      state.rows = state.rows.filter((row) => row.id !== action.payload.data.id);
    },
  }
);
const { reducer, List, Create, Update, slice: AmendOrderSlice } = bslice;

export const getAmendOrderRules = List;
export const createAmendOrderRule = Create;
export const updateAmendOrderRule = Update;
export const { setDefaults } = AmendOrderSlice.actions;

export default reducer;
