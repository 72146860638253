import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const markAsDone = createAsyncThunk(
  "@Production/markAsDone", async ({ id, ...params }, thunkApi) => {
    return await axios.put(`/api/v1/production/mark_as_done`, params);
  }
);

export const markAsUnprocessed = createAsyncThunk(
  "@Production/markAsUnprocessed", async ({ id, ...params }, thunkApi) => {
    return await axios.put(`/api/v1/production/mark_as_unprocessed`, params);
  }
);

const bslice = BaseSlice(
  'Production',
  '/api/v1/production',
  null,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [markAsDone.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.rows = data;
    },
    [markAsUnprocessed.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.rows = data;
    }
  }
);
const { reducer, List, slice: ProductionSlice } = bslice;

export const getProductionSummary = List;
// export const getOrder = Show;
// export const createOrder = Create;
// export const deleteOrder = Delete;
// export const updateOrder = Update;
export const { setDefaults } = ProductionSlice.actions;

export default reducer;
