import { BaseSlice } from 'features/baseSlice';

const bslice = BaseSlice(
  'EmployeeDeviceGroups',
  '/api/v1/employee_device_groups',
  null,
  null,
  {
    setDefaults: (state) => {}
  },
);
const { reducer, List, slice: EmployeeDGSlice } = bslice;

export const getEmployeeDeviceGroups = List;
export const { setDefaults } = EmployeeDGSlice.actions;

export default reducer;
