import { BaseSlice } from 'features/baseSlice';
import { createAction } from "@reduxjs/toolkit";

export const resetIsCreated = createAction('@EmployeeHolidays/resetIsCreated'); 

const bslice = BaseSlice(
  'EmployeeHolidays',
  '/api/v1/employee_holidays',
  null,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [resetIsCreated]: (state) => {
      state.is_created = false;
    },
  }
);
const { reducer, List, Show, Update, Create, Delete, slice: EmployeeHolidaySlice } = bslice;

export const getEmployeeHolidays = List;
export const getEmployeeHoliday = Show;
export const createEmployeeHoliday = Create;
export const deleteEmployeeHoliday = Delete;
export const updateEmployeeHoliday = Update;
export const { setDefaults } = EmployeeHolidaySlice.actions;

export default reducer;
