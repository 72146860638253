import { getIn } from "formik";

const errorProps = (name, formik) => {
    const error_message = getIn(formik.errors, name);
    const has_error = getIn(formik.touched, name);
    const helperText = has_error && error_message && String(error_message);
    const error = Boolean(has_error && error_message);
    return { error, helperText }
}

export default errorProps;