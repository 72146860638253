import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const updateMailerEnabled = createAsyncThunk(
  "@Mailers/updateMailerEnabled", async ({ id, ...params }, thunkApi) => {
    const formData = new FormData();
    formData.append("enabled", params.value);
    return await axios.put(`/api/v1/mailer_lists/${id}/update_enabled`, formData);
  }
);

const bslice = BaseSlice(
  'Mailers',
  '/api/v1/mailer_lists',
  null,
  null,
  {
    setDefaults: (state) => {
      state.mailer = null;
    }
  },
  {
    [updateMailerEnabled.fulfilled]: (state, { payload: { data: { data } } }) => {
      const { rows, selected } = state;
      state.rows = rows.map(row => (row.id === data.id) ? data : row);
      state.selected = { ...selected, ...data.attributes };
    },
  },
);
const {reducer, List, Show, Update, Create, Delete, slice: MailerSlice} = bslice;

export const getMailers = List;
export const getMailer = Show;
export const createMailer = Create;
export const deleteMailer = Delete;
export const updateMailer = Update;
export const { setDefaults } = MailerSlice.actions;

export default reducer;
