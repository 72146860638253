import { BaseSlice } from 'features/baseSlice';

const {reducer, List, Show, Update, Create, Delete} = BaseSlice('Category', '/api/v1/categories')

export const getCategories = List
export const getCategory = Show
export const createCategory = Create
export const deleteCategory = Delete
export const updateCategory = Update

export default reducer;