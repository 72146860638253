import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers'
import axios from 'axios'

export const getIngredients = createAsyncThunk(
  "@Ingredient/index", async (params, thunkApi) => { return await axios.get('/api/v1/ingredients', { params }) }
);

export const deleteIngredient = createAsyncThunk(
  "@Ingredient/delete", async (id, thunkApi) => await axios.delete(`/api/v1/ingredients/${id}`)
);

export const initialState = {
  rows: [],
  entities: {},
  selectOptions: [],
  status: 'new', // ['new', 'edited']
};

const ingredientSlice = createSlice({
  name: 'ingredient',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getIngredients.fulfilled, (state, { payload: { data: { data = [] } } }) => {

      const newEntities = Object.fromEntries(data.map(({ attributes: { name, key, type }, id, ...props }) => {
        return [key, { label: name, value: key, type, id }]

      }))

      state.entities = { ...state.entities, ...newEntities };
      state.selectOptions = Object.values(state.entities)
    })

    pageMatcher(builder)
  }
});

export const getIngredientsAsOptions = (state) => state.ingredients.selectOptions
export const getIngredientsRef = (state) => state.ingredients.entities
// export const { setDefaults } = ingredientSlice.actions
export default ingredientSlice.reducer;