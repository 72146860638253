import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import pageMatcher from '../pageMatchers'
import axios from 'axios';

export const getSuppliersOnce = createAsyncThunk(
    "@Supplier/once", async (params, { dispatch, getState }) => {
        const loaded = getSupplierIsLoaded(getState())
        if (loaded) return new Promise()
        return await axios.get('/api/v1/suppliers', { params })
    }
);

export const getSuppliers = createAsyncThunk(
    `@Supplier/index`, async (params, thunkApi) => { return await axios.get('/api/v1/suppliers', { params }) }
);

export const getSupplier = createAsyncThunk(
    `@Supplier/show`, async (id, thunkApi) => await axios.get(`/api/v1/suppliers/${id}`)
);

export const deleteSupplier = createAsyncThunk(
    `@Supplier/delete`, async (id, thunkApi) => await axios.delete(`/api/v1/suppliers/${id}`)
);

export const updateSupplier = createAsyncThunk(
    `@Supplier/update`, async ({ id, ...params }, thunkApi) => await axios.put(`/api/v1/suppliers/${id}`, params)
);

export const createSupplier = createAsyncThunk(
    "@Supplier/create", async (params, { dispatch, getState }) => {
        return await axios.post('/api/v1/suppliers', params)
    }
);

const setRelationships = (state, payload) => {
    const relationships = {}

    payload.forEach(({ type, id, attributes }) => {
        if (!relationships[type]) relationships[type] = {}
        relationships[type][id] = { id, ...attributes }
    });

    return relationships
}

export const initialState = {
    rows: [],
    entities: {},
    selectOptions: [],
    status: 'new', // ['new', 'edited']
    loaded: false,
    metaData: {},
};

const supplierSlice = createSlice({
    name: '@Supplier',
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSuppliers.fulfilled, (state, { payload: { data: { meta, data, included = [] } } }) => {
            state.rows = data;
            state.relationships = setRelationships(state, included)
            state.metaData = meta;
        })
        builder.addCase(getSuppliersOnce.fulfilled, (state, { payload: { data: { data = [] } } }) => {
            state.loaded = true
            const newEntities = Object.fromEntries(data.map(({ attributes: { name }, id }) => {
                return [id, { label: name, value: id, id }]
            }))

            state.entities = { ...state.entities, ...newEntities };
            state.selectOptions = Object.values(state.entities)
        })

        pageMatcher(builder)
    }
});

export const getSuppliersAsOptions = (state) => state.suppliers.selectOptions
export const getSuppliersRef = (state) => state.suppliers.entities
export const getSupplierIsLoaded = (state) => state.suppliers.loaded

export const { setDefaults, setLoaded } = supplierSlice.actions;
export default supplierSlice.reducer;
