import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers'
import axios from 'axios'
import { deserialize } from 'features/deserializer';
import { isEmpty } from 'lodash';

export const getProducts = createAsyncThunk(
  "@Product/index", async (params, thunkApi) => { return await axios.get('/api/v1/products', { params }) }
);

export const getProduct = createAsyncThunk(
  "@Product/show", async (id, thunkApi) => await axios.get(`/api/v1/products/${id}`).then(async ({ data }) => {
    // parse data coming from server using jsonapi-serializer
    const product = await deserialize(data)

    if (product['nutrition_info'] && !isEmpty(product['nutrition_info']['nutritions']))
      product['nutrition_info']['nutritions'] = await deserialize(product['nutrition_info']['nutritions']);

    return product
  })
);

export const createProduct = createAsyncThunk(
  "@Product/create", async (params, thunkApi) => await axios.post('/api/v1/products', params)
);

export const deleteProduct = createAsyncThunk(
  "@Product/delete", async (id, thunkApi) => await axios.delete(`/api/v1/products/${id}`)
);

export const updateProduct = createAsyncThunk(
  "@Product/update", async ({ id, ...params }, thunkApi) => await axios.put(`/api/v1/products/${id}`, params)
);

export const getProductsForAutocomplete = createAsyncThunk(
  "@Product/forAutocomplete", async (params, thunkApi) => { return await axios.get('/api/v1/products_for_autocomplete', { params }) }
);

export const getProductColumnSelect = createAsyncThunk(
  "@Product/forColumnSelect", async (params, thunkApi) => { return await axios.get('/api/v1/products/column_select', { params }) }
);

export const restoreProduct = createAsyncThunk(
  '@Product/restoreProduct', async(id, thunkApi) => {
    try {
      return await axios.put(`/api/v1/products/${id}/restore_product`);
    } catch (err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    }
  }
);

export const initialState = {
  rows: [],
  relationships: {},
  metaData: {},
  status: 'new',
  product: null
};

const setRelationships = (state, payload) => {
  const relationships = {}

  payload.forEach(({ type, id, attributes }) => {
    if (!relationships[type]) relationships[type] = {}
    relationships[type][id] = { id, ...attributes }
  });

  return relationships
}

const productSlice = createSlice({
  name: '@Product',
  initialState,
  reducers: {
    setDefaults(state) {
      state.product = null
      state.status = 'new'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (state, { payload: { data: { meta, data, included = [] } } }) => {
      state.rows = data;
      state.relationships = setRelationships(state, included)
      state.metaData = meta;
    }).addCase(getProduct.fulfilled, (state, { payload }) => {
      state.product = payload
    }).addCase(getProductsForAutocomplete.fulfilled, (state, { payload }) => {
      state.autocompleteProducts = payload.data;
    }).addCase(getProductColumnSelect.fulfilled, (state, { payload }) => {
      state.productColumns = payload.data;
    }).addCase(restoreProduct.fulfilled, (state, { payload }) => {
      state.reload = true;
      state.status = 'edited';
    });

    pageMatcher(builder)
  }
});
export const { setDefaults } = productSlice.actions

export default productSlice.reducer;
