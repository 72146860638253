import { BaseSlice } from 'features/baseSlice';

const bslice = BaseSlice(
  'EmployeeDepartments',
  '/api/v1/employee_departments',
  null,
  null,
  {
    setDefaults: (state) => {}
  },
);
const { reducer, List, slice: EmployeeDeptSlice } = bslice;

export const getEmployeeDepartments = List;
export const { setDefaults } = EmployeeDeptSlice.actions;

export default reducer;
