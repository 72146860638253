import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import WithLoader from 'utils/WithLoader';
import DialogManager from 'ui-component/dialogs/DialogManger';
import RawMaterialDetails from 'views/raw_materials/RawMaterialDetails';
// import WithLoader from 'utils/WithLoader';
// sample page routing
const RawMaterialPage = Loadable(lazy(() => import('views/raw_materials/RawMaterialsIndex')))

const ProductsIndex = Loadable(lazy(() => import('views/products/ProductsIndex')))
const ProductDetails = Loadable(lazy(() => import('views/products/ProductDetails')))

const SemiProductsIndex = Loadable(lazy(() => import('views/semi_products/SemiProductsIndex')))
const SemiProductDetails = Loadable(lazy(() => import('views/semi_products/SemiProductDetails')))

const SuppliersIndex = Loadable(lazy(() => import('views/suppliers/Index')))
const CategoriesIndex = Loadable(lazy(() => import('views/categories/Index')))
const ProductCategoriesIndex = Loadable(lazy(() => import('views/product_categories/Index')))
const StockPlacementIndex = Loadable(lazy(() => import('views/stock_placements/Index')))
const StocksIndex = Loadable(lazy(() => import('views/stocks/Index')))
const ClientsIndex = Loadable(lazy(() => import('views/clients/Index')))
const ClientsShow = Loadable(lazy(() => import('views/clients/Show')))
const AdvancedClientForm = Loadable(lazy(() => import('views/clients/AdvancedClientForm')))

const OrderIndex = Loadable(lazy(() => import('views/orders/OrderIndex')))
const OrderDetails = Loadable(lazy(() => import('views/orders/OrderDetails')))
const AdvancedOrderForm = Loadable(lazy(() => import('views/orders/AdvancedOrderForm')))

const PurchaseListsIndex = Loadable(lazy(() => import('views/purchases/PurchaseListsIndex')))
const PurchaseListCreate = Loadable(lazy(() => import('views/purchases/PurchaseListCreate')))
const PurchaseListEdit = Loadable(lazy(() => import('views/purchases/PurchaseListEdit')))

const ProductionIndex = Loadable(lazy(() => import('views/production/ProductionIndex')))
const ProductionUnitIndex = Loadable(lazy(() => import('views/production_units/Index')))
const ProductionByUnit = Loadable(lazy(() => import('views/production_units/ProductionByUnit')))

const MailerIndex = Loadable(lazy(() => import('views/mailer/MailerIndex')))

const MsOauthCallback = Loadable(lazy(() => import('views/ms_oauth/MsOauthCallback')));

const ReportsIndex = Loadable(lazy(() => import('views/reports')));

const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const RulesIndex = Loadable(lazy(() => import('views/rules/RulesIndex')));
const EntryRecordsIndex = Loadable(lazy(() => import('views/entry_records/EntryRecordsIndex')));

const EmployeesIndex = Loadable(lazy(() => import('views/employees/Index')));
const EmployeeForm = Loadable(lazy(() => import('views/employees/Form')));
const EmployeeShow = Loadable(lazy(() => import('views/employees/Show')));

const PayrollDataCreate = Loadable(lazy(() => import('views/payroll_data/PayrollDataCreate')));
const PayrollDataEdit = Loadable(lazy(() => import('views/payroll_data/PayrollDataEdit')));
const PayrollUpdateJobsShow = Loadable(lazy(() => import('views/payroll_update_jobs/PayrollUpdateJobsShow')));

const OauthIntegrations = Loadable(lazy(() => import('views/oauth_integrations/OauthIntegrations')));
const AzureActiveDirectoryCallback = Loadable(lazy(() => import('views/oauth_integrations/AzureActiveDirectoryCallback')));

const UsersIndex = Loadable(lazy(() => import('views/users/UsersIndex')));

const ReportTemplatesIndex = Loadable(lazy(() => import('views/report_templates/Index')));

const ProductColumnSelect = Loadable(lazy(() => import('views/products/forms/ColumnSelectForm')));
const SemiProductColumnSelect = Loadable(lazy(() => import('views/semi_products/ColumnSelectForm')));
const RawMaterialColumnSelect = Loadable(lazy(() => import('views/raw_materials/ColumnSelectForm')));
// ===========================|| MAIN ROUTING ||=========================== //

export const PurchaseListEditPath = '/purchase_lists/:id'

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <WithLoader>
                <DialogManager>
                    <MainLayout />
                </DialogManager>
            </WithLoader>
        </AuthGuard>
    ),
    children: [
        {
            path: '/raw_materials',
            element: <RawMaterialPage />
        },
        {
            path: '/raw_materials/:id',
            element: <RawMaterialDetails />
        },
        {
            path: '/products',
            element: <ProductsIndex />
        },
        {
            path: '/products/:id',
            element: <ProductDetails />
        },
        {
            path: '/semi_products',
            element: <SemiProductsIndex />
        },
        {
            path: '/semi_products/:id',
            element: <SemiProductDetails />
        },
        {
            path: '/suppliers',
            element: <SuppliersIndex />
        },
        {
            path: '/categories',
            element: <CategoriesIndex />
        },
        {
            path: '/product_categories',
            element: <ProductCategoriesIndex />
        },
        {
            path: '/stock_placements',
            element: <StockPlacementIndex />
        },
        {
            path: '/stocks',
            element: <StocksIndex />
        },
        {
            path: '/clients',
            element: <ClientsIndex />
        },
        {
            path: '/clients/:id',
            element: <ClientsShow />
        },
        {
            path: '/orders',
            element: <OrderIndex />
        },
        {
            path: '/orders/:id',
            element: <OrderDetails />
        },
        {
            path: '/orders/new',
            element: <AdvancedOrderForm />
        },
        {
            path: '/orders/:id/edit',
            element: <AdvancedOrderForm />
        },
        {
            path: '/clients/:id/edit',
            element: <AdvancedClientForm />
        },
        {
            path: '/clients/new',
            element: <AdvancedClientForm />
        },
        {
            path: '/purchase_lists',
            element: <PurchaseListsIndex />,
        },
        {
            path: PurchaseListEditPath,
            element: <PurchaseListEdit />,
        },
        {
            path: '/purchase_lists/new',
            element: <PurchaseListCreate />
        },
        {
            path: '/production',
            element: <ProductionIndex />
        },
        {
            path: '/production_units',
            element: <ProductionUnitIndex />
        },
        {
            path: '/production_by_unit/:id',
            element: <ProductionByUnit />
        },
        {
            path: '/mailers',
            element: <MailerIndex />
        },
        {
            path: '/users/auth/microsoft_graph/callback',
            element: <MsOauthCallback />
        },
        {
            path: '/rules',
            element: <RulesIndex />
        },
        {
            path: '/reports',
            element: <ReportsIndex />
        },
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/entry_records',
            element: <EntryRecordsIndex />
        },
        {
            path: '/employees',
            element: <EmployeesIndex />
        },
        {
            path: '/employees/:id',
            element: <EmployeeShow />
        },
        {
            path: '/employees/new',
            element: <EmployeeForm />
        },
        {
            path: '/employees/:id/edit',
            element: <EmployeeForm />
        },
        {
            path: '/payroll_data/new',
            element: <PayrollDataCreate />
        },
        {
            path: '/payroll_data/:id',
            element: <PayrollDataEdit />
        },
        {
            path: '/payroll_update_jobs/:id',
            element: <PayrollUpdateJobsShow />
        },
        {
            path: '/oauth_integrations',
            element: <OauthIntegrations />
        },
        {
            path: '/users/auth/azure_activedirectory_v2/callback',
            element: <AzureActiveDirectoryCallback />
        },
        {
            path: '/users',
            element: <UsersIndex />
        },
        {
            path: '/report_templates',
            element: <ReportTemplatesIndex />
        },
        {
            path: 'product_column_select',
            element: <ProductColumnSelect />
        },
        {
            path: 'semi_product_column_select',
            element: <SemiProductColumnSelect />
        },
        {
            path: 'raw_material_column_select',
            element: <RawMaterialColumnSelect />
        }
    ]
};

export default MainRoutes;
