import other from './other';
import settings from './settings';
import dashboard from './dashboard';
// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [other, dashboard, settings]
    // items: [other, dashboard]
};

export default menuItems;
