import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers'
import axios from 'axios'

export const getRecipe = createAsyncThunk(
    `@Recipe/show`, async (id, thunkApi) => await axios.get(`/api/v1/recipes/${id}`)
);

export const createRecipe = createAsyncThunk(
    `@Recipe/create`, async (params, thunkApi) => await axios.post('/api/v1/recipes', params)
);

export const updateRecipe = createAsyncThunk(
    `@Recipe/update`, async ({ id, ...params }, thunkApi) => await axios.put(`/api/v1/recipes/${id}`, params)
);

export const initialState = {
    rows: [],
    status: 'new',
    recipe: null
};

const setRelationships = (payload) => {
    const relationships = {}

    payload.forEach(({ type, id, attributes }) => {
        if (!relationships[type]) relationships[type] = {}
        relationships[type][id] = { id, ...attributes }
    });

    return relationships
}

const recipeSlice = createSlice({
    name: '@Recipe',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(getRecipe.fulfilled, (state, { payload: { data: { data, included = [] } } }) => {
            state.relationships = setRelationships(included)
            let ingredients;
            if (state.relationships.recipe_ingredients)
                ingredients = Object.values(state.relationships.recipe_ingredients);
            state.recipe = { ...data, ingredients };
        })

        builder.addCase(updateRecipe.fulfilled, (state, { payload: { data: { data } } }) => {
            // state.rows = data;
        })

        builder.addCase(createRecipe.fulfilled, (state, { payload: { data: { data } } }) => {
            // state.rows = data;
        })

        pageMatcher(builder)
    }
});

export const extractRecipe = (state) => state.recipes.recipe

export default recipeSlice.reducer;