import { useContext } from 'react';
import { DialogContext } from './DialogManger';

const useDialog = () => {
    const { openDialog, closeDialog } = useContext(DialogContext)


    return [openDialog, closeDialog]
}

export default useDialog;