import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false
};

const rawMaterialSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    runLoader: (state) => {
        state.loading = true
    },
    stopLoader: (state) => {
        state.loading = false
    },
  }
});

export const { runLoader, stopLoader} = rawMaterialSlice.actions
export default rawMaterialSlice.reducer;