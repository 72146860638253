import { runLoader, stopLoader } from '../features/loaderSlice'

const loader = store => next => action => {
    let result = next(action)
    let match = new RegExp('(delete|create|update|index|show)/pending$').test(action.type)
    let {loaderSlice: loading} = store.getState()
    if (match) {
        store.dispatch(runLoader());
    } else if (action.type !== 'loader/stopLoader' && loading)
        store.dispatch(stopLoader());
    return result
}

export default loader