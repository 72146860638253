// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from './actions';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const initialState = {
  isInitialized: false,
  isLoggedIn: false,
  user: {}
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            state.isLoggedIn = true;
            state.user = user;
            return { ...state };
        }
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                user: {}
            };
        }
        default: {
            return state;
        }
    }
};

export default accountReducer;
