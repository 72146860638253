import PropTypes from 'prop-types';
import React from 'react';

import {
    Chip,
    Grid,
    Stack,
    Typography
} from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';

// assets
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

// ===========================|| PRODUCT DETAILS - INFORMATION ||=========================== //

const RawMaterialInfo = ({ material }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h3">{material.name}</Typography>
                                <Chip size="small" label="New" color="primary" variant="outlined" />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Avatar variant="rounded" sx={{ bgcolor: 'grey.200', color: 'grey.800' }}>
                        <FavoriteBorderIcon />
                    </Avatar>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">{material.description}</Typography>
            </Grid>
        </Grid>
    );
};

RawMaterialInfo.propTypes = {
    material: PropTypes.object
};

export default RawMaterialInfo;
