import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from 'axios';

export const getReportTemplatesForDropdown = createAsyncThunk(
  "@ReportTemplates/forDropdown", async (params) => {
    return await axios.get('/api/v1/report_templates/for_dropdown', { params });
  }
);

export const clearSelectedTemplate = createAction('@ReportTemplates/clearSelectedTemplate'); 

const bslice = BaseSlice(
  'ReportTemplates',
  '/api/v1/report_templates',
  null,
  null,
  {
    setDefaults: (state) => {
      state.templatesDropdown = [];
    }
  },
  {
    [getReportTemplatesForDropdown.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.is_created = false;
      state.templatesDropdown = data;
    },
    [clearSelectedTemplate]: (state) => {
      state.is_created = false;
      state.selected = {};
    },
  },
);
const {reducer, List, Show, Update, Create, Delete, slice: ReportTemplateSlice} = bslice;

export const getReportTemplates = List;
export const getReportTemplate = Show;
export const createReportTemplate = Create;
export const deleteReportTemplate = Delete;
export const updateReportTemplate = Update;
export const { setDefaults } = ReportTemplateSlice.actions;

export default reducer;
