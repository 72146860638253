import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers'
import axios from 'axios'

export const getNutritions = createAsyncThunk(
  "@Nutrition/index", async (params, thunkApi) => { return await axios.get('/api/v1/nutritions', { params }) }
);

export const initialState = {
  rows: [],
  entities: {},
  selectOptions: [],
  status: 'new', // ['new', 'edited']
};

const nutritionSlice = createSlice({
  name: '@Nutrition',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNutritions.fulfilled, (state, { payload: { data: { data = [] } } }) => {

      const newEntities = Object.fromEntries(data.map(({ attributes: { name, key, type }, id, ...props }) => {
        return [key, { label: name, value: key, type, id }]
      }))

      state.entities = { ...state.entities, ...newEntities };
      state.selectOptions = Object.values(state.entities)
    })

    pageMatcher(builder)
  }
});

export const getNutritionsAsOptions = (state) => state.nutritions.selectOptions
export const getNutritionsRef = (state) => state.nutritions.entities

export default nutritionSlice.reducer;