import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { getCurrenciesAsOptions, getCurrenciesOnce, getCurrenciesRef, getCurrenciesIsLoaded, setLoaded } from 'features/currencies/currencySlice';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import errorProps from 'ui-component/inputs/error_props';

const CurrenciesAutocomplete = ({ name, ...props }) => {
    const dispatch = useDispatch();
    const formik = useFormikContext()
    const options = useSelector(getCurrenciesAsOptions)
    const optionsRef = useSelector(getCurrenciesRef)
    const loaded = useSelector(getCurrenciesIsLoaded)

    const value = getIn(formik.values, name) || ''
    const errors = errorProps(name, formik)

    useEffect(() => {
        if (!loaded) {
            dispatch(getCurrenciesOnce())
            dispatch(setLoaded());
        }
    }, [loaded, dispatch])

    const handleChange = (event, newValue, reason) => {
        if (newValue && reason === 'selectOption') formik.setFieldValue(name, newValue.value);
        if (reason === 'clear') formik.setFieldValue(name, '');
    }

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        stringify: (option) => option.label,
    });

    return (<Autocomplete
        disablePortal
        size="small"
        id='add-nutrition'
        options={options}
        value={value}
        filterOptions={filterOptions}
        onChange={handleChange}
        // onBlur={() => formik.setTouched({ ...formik.touched, [name]: true })}
        getOptionLabel={(option) => getIn(optionsRef, `${option}.label`) || ''}
        renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}
        isOptionEqualToValue={(option, val) => {
            return option.value === val
        }}
        renderInput={(params) => {
            return <TextField size="small" {...params} {...errors} />
        }}
    />)
}

export default CurrenciesAutocomplete;