import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers'
import axios from 'axios'

export const getUnits = createAsyncThunk(
    "@Unit/index", async (params, thunkApi) => { return await axios.get('/api/v1/units', { params }) }
);

export const getUnitsOnce = createAsyncThunk(
    "@Unit/once", async (params, { dispatch, getState }) => {
        const loaded = getUnitsIsLoaded(getState())
        if (loaded) return new Promise()
        return await axios.get('/api/v1/units', { params })
    }
);

export const initialState = {
    rows: [],
    entities: {},
    selectOptions: [],
    status: 'new', // ['new', 'edited']
    loaded: false
};

const unitSlice = createSlice({
    name: 'unit',
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true
        },
    },
    extraReducers: (builder) => {
        [getUnits.fulfilled, getUnitsOnce.fulfilled].forEach((action) => {
            builder.addCase(action, (state, { payload: { data: { data = [] } } }) => {

                const newEntities = Object.fromEntries(data.map(({ attributes: { code }, id, ...props }) => {
                    return [id, { label: code, value: id, id }]
                }))

                state.entities = { ...state.entities, ...newEntities };
                state.selectOptions = Object.values(state.entities)
            })
        })
        pageMatcher(builder)
    }
});

export const getUnitsIsLoaded = (state) => state.units.loaded
export const getUnitsAsOptions = (state) => state.units.selectOptions
export const getUnitsRef = (state) => state.units.entities
export const { setLoaded } = unitSlice.actions
export default unitSlice.reducer;