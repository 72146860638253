import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from "react-redux";
import { filter } from 'lodash';

// material-ui
import { makeStyles } from '@mui/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// style constant
const useStyles = makeStyles((theme) => ({
    menuCaption: {
        ...theme.typography.menuCaption
    },
    subMenuCaption: {
        ...theme.typography.subMenuCaption
    },
    menuDivider: {
        marginTop: '2px',
        marginBottom: '10px'
    }
}));

// ===========================|| SIDEBAR MENU LIST GROUP ||=========================== //

const NavGroup = ({ item }) => {
    const classes = useStyles();
    const { user: { role: userRole } } = useSelector((state) => state.account);

    const itemsToShow = filter(item.children, (i) => {
      if (userRole === 'hr') {
        return i.showForHr;
      } else if (i.adminOnly) {
        return userRole === 'admin' || userRole === 'dev';
      } else if (i.devOnly) {
        return userRole === 'dev';
      } else {
        return true;
      }
    });

    // menu list collapse & items
    const items = itemsToShow.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" className={classes.menuCaption} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" className={classes.subMenuCaption} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider className={classes.menuDivider} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
