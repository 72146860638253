import { BaseSlice } from 'features/baseSlice';

const bslice = BaseSlice(
  'Users',
  '/api/v1/users',
  {
    rows: [],
    metaData: {},
    isLoading: false,
    error: false,
    selected: {},
  },
  null,
  {
    setDefaults: (state) => {
      state.user = null;
    }
  },
  {}
);
const { reducer, List, Create, Update, Delete, slice: UserSlice } = bslice;

export const getUsers = List;
export const createUser = Create;
export const updateUser = Update;
export const deleteUser = Delete;

export const { setDefaults } = UserSlice.actions;

export default reducer;

