import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from 'ui-component/Loader';
import { useSelector } from 'react-redux';
// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
let timer = null

const WithLoader = ({ children }) => {
    const { loading } = useSelector((state) => state.loaderSlice)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (loading && !timer) {
            setIsLoading(true)
            timer = setTimeout(() => {
                setIsLoading(false)
                timer = null
            }, 500)
        }
    }, [loading])

    return (<>
        {isLoading ? <Loader /> : null}
        {children}
    </>);
};

WithLoader.propTypes = {
    children: PropTypes.node
};

export default WithLoader;
