import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, some } from 'lodash';

// material-ui
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import Article from '@mui/icons-material/Article'

// project imports
import { getProductionUnitDropdown } from 'features/production_units/productionUnitSlice';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
    const dispatch = useDispatch();
    const { productionUnitDropdown: prodUnits = [] } = useSelector((state) => state.production_units);
    const [menu, setMenu] = useState(menuItem.items);

    useEffect(() => {
        dispatch(getProductionUnitDropdown());
    }, [dispatch]);

    useEffect(() => {
        if (!isEmpty(prodUnits)) {
            const dynamicProdUnitMenu = prodUnits.map((pu) => {
                const { id, attributes: { title } } = pu;
                return ({
                    id: `production_unit_${id}`,
                    title: <FormattedMessage id={title} />,
                    type: 'item',
                    url: `/production_by_unit/${id}`,
                    icon: Article,
                    breadcrumbs: false
                });
            });
            let filteredMenu = [];

            if (!some(menu[1].children, ['id', 'production_unit'])) {
                filteredMenu = menu;
                filteredMenu[1].children.push({
                    id: 'production_unit',
                    title: <FormattedMessage id="Production unit" />,
                    type: 'collapse',
                    icon: Article,
                    breadcrumbs: false,
                    children: dynamicProdUnitMenu
                });
            } else {
                filteredMenu = menu.map((item) => {
                    if (item.id !== 'dashboard') {
                        return item;
                    } else {
                    item.children = item.children.map((child) => {
                            if (child.id === 'production_unit') {
                                child.children = dynamicProdUnitMenu;
                            }
                            return child;
                        });
                        return item;
                    }
                });
            }
            setMenu(filteredMenu);
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuItem, prodUnits]);

    const navItems = menu.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
