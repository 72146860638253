
export const pageState = {
    status: 'new',
}

const showFailedMatcher = (action) => {
    return action.type.endsWith('show/rejected')
}
const IndexFailedMatcher = ({type}) => type.endsWith('index/rejected')
const IndexLoadedMatcher = ({type}) => new RegExp('(index|show)/fulfilled$').test(type)
const EditedMatcher = ({type}) => new RegExp('(delete|create|update)/fulfilled$').test(type)

export const pageInitialState = {
    status: 'new',
    isLoading: false,
}

const pageMatcher = (builder) => {
    return builder.addMatcher(showFailedMatcher, (state) => {
        state.status = 'unavailable'
    }).addMatcher(IndexLoadedMatcher, (state) => {
        if (state.status === 'edited') {
            state.status = 'new'
        }
    }).addMatcher(IndexFailedMatcher, (state) => {
        state.rows = []
    }).addMatcher(EditedMatcher, (state) => {
        state.status = 'edited'
    })
    

}

export default pageMatcher;