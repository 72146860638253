import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const getKgroupDropdown = createAsyncThunk(
  'Kgroups/forDropdown', async () => {
    return await axios.get('/api/v1/kgroups/for_dropdown');
  }
);

const bslice = BaseSlice(
  'Kgroups',
  '/api/v1/kgroups',
  null,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [getKgroupDropdown.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.kgroupsDropdown = data;
    },
  }
);
const { reducer, List, Update, Create, Delete } = bslice;

export const getKgroups = List;
export const createKgroup = Create;
export const deleteKgroup = Delete;
export const updateKgroup = Update;

export default reducer;
