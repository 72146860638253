import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getTracesOnce = createAsyncThunk(
    "@Trace/once", async (params, { dispatch, getState }) => {
        const loaded = getTraceIsLoaded(getState())
        if (loaded) return new Promise()
        return await axios.get('/api/v1/traces', { params })
    }
);

export const createTrace = createAsyncThunk(
    "@Trace/create", async (params, { dispatch, getState }) => {
        return await axios.post('/api/v1/traces', params)
    }
);

export const initialState = {
    entities: {},
    selectOptions: [],
    status: 'new', // ['new', 'edited']
    loaded: false
};

const traceSlice = createSlice({
    name: '@Trace',
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTracesOnce.fulfilled, (state, { payload: { data: { data = [] } } }) => {
            state.loaded = true
            const newEntities = Object.fromEntries(data.map(({ attributes: { name }, id }) => {
                return [id, { label: name, value: id, id }]
            }))

            state.entities = { ...state.entities, ...newEntities };
            state.selectOptions = Object.values(state.entities)
        })

        builder.addCase(createTrace.fulfilled, (state) => {
            state.loaded = false
        })
    }
});

export const getTracesAsOptions = (state) => state.traces.selectOptions
export const getTracesRef = (state) => state.traces.entities
export const getTraceIsLoaded = (state) => state.traces.loaded

export const { setDefaults, setLoaded } = traceSlice.actions;
export default traceSlice.reducer;
