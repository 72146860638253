import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const markAsDone = createAsyncThunk(
  "@Production/markAsDone", async ({ id, ...params }, thunkApi) => {
    return await axios.put(`/api/v1/production/mark_as_done`, params);
  }
);

export const markAsUnprocessed = createAsyncThunk(
  "@Production/markAsUnprocessed", async ({ id, ...params }, thunkApi) => {
    return await axios.put(`/api/v1/production/mark_as_unprocessed`, params);
  }
);

export const deleteAmendOrderLineRule = createAsyncThunk(
  "@AmendOrderLine/delete", async (id, thunkApi) => await axios.delete(`/api/v1/amend_order_line_rules/${id}`)
);

const bslice = BaseSlice(
  'AmendOrderLine',
  '/api/v1/amend_order_line_rules',
  null,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [markAsDone.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.rows = data;
    },
    [markAsUnprocessed.fulfilled]: (state, { payload: { data: { data } } }) => {
      state.rows = data;
    },
    [deleteAmendOrderLineRule.fulfilled]: (state, action) => {
      state.reload = true;
      state.status = 'edited'
      state.rows = state.rows.filter((row) => row.id !== action.payload.data.id);
    },
  }
);
const { reducer, List, Create, Update, slice: AmendOrderLineSlice } = bslice;

export const getAmendOrderLineRules = List;
export const createAmendOrderLineRule = Create;
export const updateAmendOrderLineRule = Update;
export const { setDefaults } = AmendOrderLineSlice.actions;

export default reducer;
