import React, { useState } from 'react';
import {
    InputLabel,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { uniqueId } from 'lodash';

const useStyles = makeStyles((theme) => ({
    fileInput: {
        display: 'none'
    },
    fileContainer: {
        background: theme.palette.background.default,
        textAlign: 'center',
        borderRadius: '4px',
        cursor: 'pointer',
        '& > svg': {
            verticalAlign: 'sub',
            marginRight: '5px'
        }
    },
    uploadImage: {
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '4px',
        cursor: 'pointer',
        width: '55px',
        height: '55px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.palette.background.default,
        '& > svg': {
            verticalAlign: 'sub',
            marginRight: '5px'
        }
    },
    productProgress: {
        position: 'absolute',
        left: '0',
        top: '0',
        background: 'rgba(255, 255, 255, .8)',
        width: '100% !important',
        height: '100% !important',
        padding: '12px'
    }
}));

const UploadButton = ({ onAttached, uploadText, name }) => {
    const classes = useStyles();
    const formik = useFormikContext()
    const [fileUploadData, setFileUploadData] = useState({})
    const [, setAttachement] = useState([])

    const handleUpload = (event) => {
        const _files = Array.from(event.currentTarget.files)

        let uploadData = {}
        _files.forEach((file) => {
            uploadData = { file: file, progress: false, uploading: true }
            const form = new FormData();
            form.append('file', file);

            const config = {
                onUploadProgress: event => {
                    uploadData.progress = parseInt((event.loaded / event.total) * 100);
                    setFileUploadData(Object.assign({}, uploadData));
                }
            }

            axios.post('/api/v1/attachments', form, config).then(({ data }) => {
                setAttachement(data);
                formik.setFieldValue(name, data.id);
            }).finally(() => {
                uploadData.uploading = false;
                setFileUploadData(Object.assign({}, uploadData));
            })

        })

        setFileUploadData(Object.assign({}, uploadData))
    }
    const inputId = `file-upload-${uniqueId()}`;
    return (
        <>
            <input onChange={handleUpload} type="file" id={inputId} className={classes.fileInput} />
            <InputLabel htmlFor={inputId} className={classes.fileContainer}>
                <Typography component='span' sx={{ marginRight: '5px' }}>{fileUploadData.file && fileUploadData.file.name}</Typography>
                <CloudUploadIcon style={{ verticalAlign: "middle" }} />
            </InputLabel>
        </>
    );
};

export default UploadButton;
