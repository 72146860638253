import { BaseSlice } from 'features/baseSlice';

const {reducer, List, Show, Update, Create, Delete} = BaseSlice('StockPlacement', '/api/v1/stock_placements')

export const getStockPlacements = List
export const getStockPlacement = Show
export const createStockPlacement = Create
export const deleteStockPlacement = Delete
export const updateStockPlacement = Update

export default reducer;