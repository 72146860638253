import { BaseSlice } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const updateOrderStatus = createAsyncThunk(
  "@Orders/updateStatus", async ({ id, ...params }, thunkApi) => {
    return await axios.put(`/api/v1/orders/${id}/update_status`, params);
  }
);

const bslice = BaseSlice(
  'Orders',
  '/api/v1/orders',
  null,
  null,
  {
    setDefaults: (state) => {
      state.order = null;
    }
  },
  {
    [updateOrderStatus.fulfilled]: (state, { payload: { data: { data } } }) => {
      const { rows, selected } = state;
      state.rows = rows.map(row => (row.id === data.id) ? data : row);
      state.selected = { ...selected, ...data.attributes };
    },
  }
);
const { reducer, List, Show, Update, Create, Delete, slice: OrderSlice } = bslice;

export const getOrders = List;
export const getOrder = Show;
export const createOrder = Create;
export const deleteOrder = Delete;
export const updateOrder = Update;
export const { setDefaults } = OrderSlice.actions;

export default reducer;
