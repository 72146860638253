import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import pageMatcher from '../pageMatchers';
import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer';

const deserialize = async (data) => {
  const jsonapi = new Deserializer({ keyForAttribute: 'snake_case' });
  return await jsonapi.deserialize(data)
}

export const getPurchaseLists = createAsyncThunk(
  "@PurchaseList/index", async (params, thunkApi) => { return await axios.get('/api/v1/purchase_lists', { params }) }
);

export const getPurchaseList = createAsyncThunk(
  "@PurchaseList/show", async (id, thunkApi) => await axios.get(`/api/v1/purchase_lists/${id}`).then(async ({ data }) => {
    return await deserialize(data)
  })
);

export const createPurchaseListReq = (params) => axios.post('/api/v1/purchase_lists', params)

export const createPurchaseList = createAsyncThunk("@PurchaseList/create", async (params) => await createPurchaseListReq(params));

export const deletePurchaseList = createAsyncThunk(
  "@PurchaseList/delete", async (id, thunkApi) => await axios.delete(`/api/v1/purchase_lists/${id}`)
);

export const updatePurchaseList = createAsyncThunk(
  "@PurchaseList/update", async ({ id, ...params }, thunkApi) => await axios.put(`/api/v1/purchase_lists/${id}`, params)
);

export const initialState = {
  rows: [],
  relationships: {},
  metaData: {},
  status: 'new',
  purchase_list: null
};

const setRelationships = (state, payload) => {
  const relationships = {}

  payload.forEach(({ type, id, attributes }) => {
    if (!relationships[type]) relationships[type] = {}
    relationships[type][id] = { id, ...attributes }
  });

  return relationships
}

const purchase_listSlice = createSlice({
  name: '@PurchaseList',
  initialState,
  reducers: {
    setDefaults(state) {
      state.purchase_list = null
      state.status = 'new'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchaseLists.fulfilled, (state, { payload: { data: { meta, data, included = [] } } }) => {
      state.rows = data;
      state.relationships = setRelationships(state, included)
      state.metaData = meta;
    }).addCase(getPurchaseList.fulfilled, (state, { payload }) => {
      state.purchase_list = payload
    })

    pageMatcher(builder)
  }
});
export const { setDefaults } = purchase_listSlice.actions

export default purchase_listSlice.reducer;
