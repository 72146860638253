import { Autocomplete, TextField } from '@mui/material';
import { getUnitsAsOptions, getUnitsOnce, getUnitsRef, getUnitsIsLoaded, setLoaded } from 'features/units/unitSlice';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import errorProps from 'ui-component/inputs/error_props';

const UnitsAutocomplete = ({ name, className, ...props }) => {
    const dispatch = useDispatch();
    const formik = useFormikContext()
    const options = useSelector(getUnitsAsOptions)
    const optionsRef = useSelector(getUnitsRef)
    const loaded = useSelector(getUnitsIsLoaded)

    const value = getIn(formik.values, name) || ''
    const errors = errorProps(name, formik)

    useEffect(() => {
        if (!loaded) {
            dispatch(getUnitsOnce())
            dispatch(setLoaded());
        }
    }, [loaded, dispatch])

    const handleChange = (event, newValue, reason) => {
        if (newValue && reason === 'selectOption') formik.setFieldValue(name, newValue.value);
        if (reason === 'clear') formik.setFieldValue(name, '');
    }

    return (<Autocomplete
        disablePortal
        size="small"
        id='add-nutrition'
        options={options}
        value={value}
        className={className}
        onChange={handleChange}
        // onBlur={() => formik.setTouched({ ...formik.touched, [name]: true })}
        getOptionLabel={(option) => getIn(optionsRef, `${option}.label`) || ''}
        renderOption={(props, option) => <li {...props} key={option.value}>{option.label}</li>}
        isOptionEqualToValue={(option, val) => {
            return option.value === val
        }}
        renderInput={(params) => {
            return <TextField size="small" {...params} {...errors} />
        }}
    />)
}

export default UnitsAutocomplete;
