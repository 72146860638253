import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAllergensOnce = createAsyncThunk(
    "@Allergen/once", async (params, { dispatch, getState }) => {
        const loaded = getAllergenIsLoaded(getState())
        if (loaded) return new Promise()
        return await axios.get('/api/v1/allergens', { params })
    }
);

export const createAllergen = createAsyncThunk(
    "@Allergen/create", async (params, { dispatch, getState }) => {
        return await axios.post('/api/v1/allergens', params)
    }
);

export const initialState = {
    entities: {},
    selectOptions: [],
    status: 'new', // ['new', 'edited']
    loaded: false
};

const allergenSlice = createSlice({
    name: '@Allergen',
    initialState,
    reducers: {
        setLoaded(state) {
            state.loaded = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllergensOnce.fulfilled, (state, { payload: { data: { data = [] } } }) => {
            state.loaded = true
            const newEntities = Object.fromEntries(data.map(({ attributes: { name }, id }) => {
                return [id, { label: name, value: id, id }]
            }))

            state.entities = { ...state.entities, ...newEntities };
            state.selectOptions = Object.values(state.entities)
        })

        builder.addCase(createAllergen.fulfilled, (state) => {
            state.loaded = false
        })
    }
});

export const getAllergensAsOptions = (state) => state.allergens.selectOptions
export const getAllergensRef = (state) => state.allergens.entities
export const getAllergenIsLoaded = (state) => state.allergens.loaded

export const { setDefaults, setLoaded } = allergenSlice.actions;
export default allergenSlice.reducer;
