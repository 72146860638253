import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'
import { deserialize } from 'features/deserializer';
import pageMatcher from 'features/pageMatchers';

export const getRawMaterials = createAsyncThunk(
  "@RawMaterial/index", async (params, thunkApi) => {
    return await axios.get('/api/v1/raw_materials', { params })
      .then(async ({ data }) => {
        return { ...data, data: await deserialize({ data: data.data, included: data.included }) }
      })
  });

export const getRawMaterial = createAsyncThunk(
  "@Product/show", async (id, thunkApi) => await axios.get(`/api/v1/raw_materials/${id}`).then(async ({ data }) => {
    // parse data coming from server using jsonapi-serializer
    return await deserialize(data);
  })
);

export const createRawMaterial = createAsyncThunk(
  "@RawMaterial/create", async (params, thunkApi) => await axios.post('/api/v1/raw_materials', params)
);

export const deleteRawMaterial = createAsyncThunk(
  "@RawMaterial/create", async (id, thunkApi) => await axios.delete(`/api/v1/raw_materials/${id}`)
);

export const updateRawMaterial = createAsyncThunk(
  "@RawMaterial/update", async ({ id, ...params }, thunkApi) => await axios.put(`/api/v1/raw_materials/${id}`, params)
);

export const getRawMaterialColumnSelect = createAsyncThunk(
  "@SemiProduct/forColumnSelect", async (params, thunkApi) => { return await axios.get('/api/v1/raw_materials/column_select', { params }) }
);

const rawMaterialsAdapter = createEntityAdapter()
export const initialState = {
  rows: [],
  ...rawMaterialsAdapter.getInitialState(),
  metaData: {},
  status: 'new', // ['new', 'edited']
  raw_material: null,
  rawMaterialColumns: [],
};

const rawMaterialSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDefaults(state) {
      state.raw_material = null
      state.status = 'new'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRawMaterials.fulfilled, (state, { payload: { meta, data } }) => {
      state.rows = data;
      rawMaterialsAdapter.addMany(state, data)
      state.metaData = meta;
    }).addCase(getRawMaterial.fulfilled, (state, {payload}) => {
      state.raw_material = payload;
    }).addCase(getRawMaterialColumnSelect.fulfilled, (state, { payload }) => {
      state.rawMaterialColumns = payload.data;
    });

    pageMatcher(builder)
  }

});
export const actions = rawMaterialSlice.actions
export const { setDefaults } = rawMaterialSlice.actions

export default rawMaterialSlice.reducer;
