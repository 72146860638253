import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { BaseSlice } from "features/baseSlice";

// Reusable function to create API thunks
const createApiThunk = (actionType, apiUrl) => {
    return createAsyncThunk(
        actionType,
        async (params) => {
            return await axios.get(apiUrl, { params });
        }
    );
};
export const getEntryRecordsData = createApiThunk(
    "@EntryRecord/getEntryRecordsData",
    '/api/v1/entry_records/day_view'
);

const initialState = {
    rows: [],
    data: [],
    metaData: {},
    isLoading: false,
    error: false,
    selected: {},
    dayView: [],
};

const bslice = BaseSlice(
    'EntryRecordFilter',
    '/api/v1/entry_records',
    initialState,
    null,
    {
        setDefaults: (state) => { }
    },
    {
        [getEntryRecordsData.pending]: (state) => {
            state.isLoading = true;
            state.metaData = {};
        },
        [getEntryRecordsData.fulfilled]: (state, { payload: { data: { data, meta } } }) => {
            state.isLoading = false;
            state.rows = data;
            state.dayView = data;
            state.metaData = meta;
            state.isLoading = false;
        },
    }
);

const { reducer, List, slice: entryRecordFilterSlice } = bslice;

export const getEntryRecords = List;
export const { setDefaults } = entryRecordFilterSlice.actions;

export default reducer;
