import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome } from '@tabler/icons';

// constant
const icons = { IconBrandChrome };

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const other = {
    id: 'other',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Home" />,
            type: 'item',
            url: '/',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
    ]
};

export default other;
